/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  useContentfulLiveUpdates,
} from '@contentful/live-preview/react';
import { ContentfulLivePreview } from '@contentful/live-preview';

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulFlipbook(slug: { eq: $slug }) {
      slug
      title
      description
      contentful_id
      __typename
      id
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
  }
`;

function PreviewWrapper({ data }) {
  const { contentfulFlipbook } = data;

  const updatedFlipbook = useContentfulLiveUpdates({
    ...contentfulFlipbook,
    sys: { id: contentfulFlipbook.contentful_id },
  });

  const {
    slug, title, description, contentful_id,
  } = updatedFlipbook;

  return (
    <>
      <pre>
        PREVIEW WRAPPER
      </pre>
      <h1>
        {slug}
      </h1>
      <h2>
        {title}
      </h2>
      <p>
        {description}
      </p>
      <p {...ContentfulLivePreview.getProps({ entryId: contentful_id, fieldId: 'description' })}>
        {description}
      </p>
      <p>
        {contentful_id}
      </p>
    </>
  );
}

PreviewWrapper.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PreviewWrapper;
